//@ts-check
/**
 * @import { CommonExtensionCardData} from "../assertCardCommonDataTypes";
 * @typedef { CommonExtensionCardData  } CardDataType
 */

import { assertCardCommonDataTypes } from '../assertCardCommonDataTypes';

/**
 *
 * @param {unknown} data
 *
 * @returns {CardDataType}
 */
export function assertCardDataTypes(data) {
  return assertCardCommonDataTypes(data);
}
