import React, { useState, useEffect } from 'react';
import Chip from '../../../general/Chip/Chip';
import { FiClock } from 'react-icons/fi';
import { ImagePlaceholder } from '../../../../assets/icons/buttons/ImagePlaceholder';
import { getPage } from 'addons/utfpr_components/src/helpers/getPage';
import { getSubjectFromPage } from 'addons/utfpr_components/src/helpers/getSubjectFromPage';
import { CustomLink } from '../../../general/CustomLink/CustomLink';

const MainNewsItemColumn = ({
  img,
  tags,
  title,
  link,
  description,
  date,
  editable,
  showImage,
}) => {
  const [imageIsVisible, setImageIsVisible] = useState(showImage);

  const [hat, setHat] = useState('');

  useEffect(() => {
    async function getHat() {
      const page = await getPage(link);
      if (!page) return;
      const subject = await getSubjectFromPage(page);
      setHat(subject);
    }
    getHat();
  }, [link]);

  return (
    <CustomLink
      className={`w-full ${!showImage ? 'h-[197px]' : 'h-[260px]'} md:h-[240px] group hover:text-[#005DB7] bg-white hover:bg-[#f5f5f5] dark:bg-[#001C3A] dark:hover:bg-[#003063] transition duration-300 shadow rounded overflow-hidden`}
      href={link}
      isEditMode={editable}
    >
      <div className="w-full flex">
        <div className="w-full flex flex-col gap-y-2 p-3 md:gap-y-3 md:p-5 md:pr-10">
          <h5 className="font-semibold text-[#2E3132] text-[13px] line-clamp-1 uppercase dark:text-[#F8FAFA] md:text-base">
            {hat}
          </h5>
          <div className={`text-[17px] leading-6 tracking-[0.5px] font-bold text-[#2E3132] dark:text-[#F8FAFA] group-hover:text-[#005DB7] group-hover:dark:text-[#F8FAFA] md:text-[20px] md:leading-7 ${!showImage ? 'line-clamp-2' : 'line-clamp-4 md:line-clamp-2'}`}>
            {title}
          </div>
          <div className="hidden md:flex text-[#8D9199] text-[14px] flex-row lg:text-[13px] xl:text-[14px] items-center">
            Publicado {date}, última modificação {date}
          </div>
          <div className="flex md:hidden items-center text-[#8D9199] gap-2">
            <FiClock className="text-[15px]" />
            <span className="text-[14px]">{date}</span>
          </div>
          <div className={`text-[13px] text-left leading-[16px] text-[#0D1117] dark:text-[#F8FAFA] md:text-base ${!showImage ? 'line-clamp-2' : 'line-clamp-3 md:line-clamp-2'}`}>
            {description}
          </div>
          {tags.length > 0 && (
            <div className="flex justify-start items-start gap-2 mt-auto flex-wrap">
              {tags.map((tag, index) => {
                return <Chip key={index} text={tag} />;
              })}
            </div>
          )}
        </div>
        <div className={`w-[140px] h-[260px] min-w-[140px] md:w-[240px] md:h-[240px] md:min-w-[240px] ${!showImage ? 'hidden md:block' : ''}`}>
          {showImage && imageIsVisible ? (
            <img
              src={img}
              alt={title}
              className="w-full h-full object-cover group-hover:scale-110 transition duration-300"
              loading="lazy"
              onError={() => setImageIsVisible(false)}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              {!showImage && <div className="w-full h-full"></div>}
              {showImage && !imageIsVisible && (
                <ImagePlaceholder
                  color="#000000"
                  className="dark:[&_path]:stroke-[#FFFFFF] group-hover:[&_path]:stroke-[#005DB7]"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default MainNewsItemColumn;