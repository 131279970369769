//@ts-check
import React, { useContext, useState, useRef, useEffect } from 'react';
import { EditTableContext } from '../../../store';
import { setCell } from '../helpers';
import { CellOptionsMenu } from '../components/CellOptionsMenu/CellOptionsMenu';
//@ts-ignore
import AddLinkForm from '@plone/volto/components/manage/AnchorPlugin/components/LinkButton/AddLinkForm';
import { alignToCssClass } from '../helpers/alignToCssClass';
import { urlHandler } from '../../../../../helpers/urlHandler';

// primeiramente adicione a tipagem da sua celula em "Cell/type.d.ts"
/**
 * @param {import('../../types').CellProps<"text">} props
 */
export const TextCellEdit = (props) => {
  // nos edits use o "EditableTableContext" para ter acesso a tabela
  const { table, setTable } = useContext(EditTableContext),
    [isSelected, setIsSelected] = useState(false),
    [link, setLinkProto] = useState(props.attributes.link),
    [openNewWindow, setOpenNewWindowProto] = useState(
      props.attributes.openNewWindow,
    );

  // pegando o texto já salvo na tabela
  const [text, setText] = useState(props.text),
    // pegando o tipo de texto já salvo na tabela
    [ele, setEle] = useState(props.attributes.el),
    [align, setAlign] = useState(
      props.attributes.align ? props.attributes.align : 'left',
    );

  /** @type {React.MutableRefObject<null|HTMLTextAreaElement>} */
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';

      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  });

  /**
   *
   * @param {string} link
   */
  const setLink = (link) => {
    setLinkProto(link);

    const cell = { ...props };

    cell.attributes.link = link;

    setCell({ setTable: setTable, table: table, cell: cell });
  };

  /**
   *
   * @param {boolean} openNewWindow
   */
  const setOpenNewWindow = (openNewWindow) => {
    setOpenNewWindowProto(openNewWindow);

    const cell = { ...props };

    cell.attributes.openNewWindow = openNewWindow;

    setCell({ setTable: setTable, table: table, cell: cell });
  };

  return (
    <>
      <textarea
        onBlur={() => {
          // muda para não selecionado quando algum outro elemento é clickado
          setIsSelected(false);
        }}
        onSelect={() => {
          setIsSelected(true);
        }}
        ref={textareaRef}
        onChange={(el) => {
          //texto do input
          const inputText = el.target.value;
          // pegando a entrada de texto no componente
          if (inputText.length < 501) setText(inputText);
          else setText(inputText.substring(0, 500));

          //copiando conteudo da celula e alterando o necessario
          const cell = { ...props };
          cell.text = inputText;
          // usando o setCell já importado
          setCell({ setTable: setTable, table: table, cell: cell });
        }}
        className={`resize-none h-full break-words w-full ${
          ele == 'h1'
            ? 'text-[56px] font-bold flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-[84px] [&_.tabela-linha-media]:leading-[84px] [&_.tabela-linha-grande]:leading-[126px]'
            : ele == 'h2'
            ? 'text-5xl font-bold flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-[72px] [&_.tabela-linha-media]:leading-[72px] [&_.tabela-linha-grande]:leading-[108px]'
            : 'flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-6 [&_.tabela-linha-media]:leading-6 [&_.tabela-linha-grande]:leading-9'
        } ${alignToCssClass(align)}`}
        placeholder="texto"
        value={text}
      ></textarea>
      {/* adiciona o menu de edição da celula */}
      <CellOptionsMenu cell={{ ...props }} isCellSelected={isSelected}>
        <div className="flex flex-col gap-2">
          <select
            onChange={(el) => {
              // settando o tipo de elemento textual
              if (
                el.target.value == 'p' ||
                el.target.value == 'h1' ||
                el.target.value == 'h2'
              ) {
                setEle(el.target.value);

                // clonando a celula
                const cell = { ...props };

                cell.attributes.el = el.target.value;

                setCell({ setTable: setTable, table: table, cell: cell });
              }
            }}
            defaultValue={ele}
            className="w-full"
          >
            <option value={'p'}>paragrafo</option>
            <option value={'h1'}>titulo 1</option>
            <option value={'h2'}>titulo 2</option>
          </select>
          <select
            onChange={(el) => {
              // settando o tipo de elemento textual
              if (
                el.target.value == 'left' ||
                el.target.value == 'center' ||
                el.target.value == 'right'
              ) {
                setAlign(el.target.value);

                // clonando a celula
                const cell = { ...props };

                cell.attributes.align = el.target.value;

                setCell({ setTable: setTable, table: table, cell: cell });
              }
            }}
            defaultValue={align}
            className="w-full"
          >
            <option value={'center'}>centro</option>
            <option value={'left'}>esquerda</option>
            <option value={'right'}>direita</option>
          </select>
          <span>link: {urlHandler(link || '')}</span>
          <AddLinkForm
            block="draft-js"
            placeholder={'Add'}
            theme={{}}
            data={{ url: link }}
            onChangeValue={(link) => {
              setLink(link);
            }}
            onClear={() => {
              setLink('');
            }}
            onOverrideContent={(c) => {}}
          ></AddLinkForm>
          {!link ? (
            <></>
          ) : (
            <label className="gap-4 flex flex-row">
              <input
                type="checkbox"
                checked={openNewWindow}
                onChange={(ev) => {
                  setOpenNewWindow(ev.target.checked);
                }}
              ></input>
              abrir em uma nova aba
            </label>
          )}
        </div>
      </CellOptionsMenu>
    </>
  );
};
// quando o edit já estiver concluido crie o view (ou ao contrario caso seja sua preferencia)
