//@ts-check
import React from 'react';

/** @type {React.FC<{children:React.ReactNode}>} */
export const Responsible = ({ children }) => {
  return (
    <div className="flex flex-col gap-4 items-start [&_*]:text-[#8D9199]">
      <span className="text-base font-bold dark:!text-gray-50 !text-darkModeBg">
        Responsavél:
      </span>
      {children}
    </div>
  );
};
