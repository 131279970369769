import icon from '@plone/volto/icons/list-bullet.svg';
import { ExtensionProjectCardsEdit } from './ExtensionProjectCardsEdit';
import { ExtensionProjectCardsView } from './ExtensionProjectCardsView';

export default {
  id: 'extensionProjectCards',
  title: 'Cards-projeto de extensão',
  group: 'common',
  view: ExtensionProjectCardsView,
  edit: ExtensionProjectCardsEdit,
  icon: icon,
  restricted: false,
  mostUsed: true,
  sidebarTab: 1,
};
