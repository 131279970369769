//@ts-check
/**
 *  @import { CardDataType } from "./assertCardDataTypes";
 */
/**/
import { tagSchema } from '../tagSchema';

export const compactSchema = {
  title: 'projeto de extensão',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['img', 'title', 'text', 'tags'],
    },
  ],
  properties: {
    img: {
      title: 'imagem',
      widget: 'url',
    },
    title: {
      title: 'Título',
      type: 'string',
      widget: 'text',
    },
    text: {
      title: 'Título',
      type: 'string',
      widget: 'text',
    },
    tags: {
      title: 'tags',
      widget: 'object_list',
      schema: tagSchema,
    },
  },
};
