import {useEffect, useState} from 'react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { formatDate } from '../../../../helpers/Utils/Utils';
import { IconDownload } from '../../../../addons/utfpr_components/src/assets/icons/buttons/IconDownload';
import { IconDiagonalTopRightArrow } from '../../../../addons/utfpr_components/src/assets/icons/buttons/IconDiagonalTopRightArrow';
import { IconDocumentWriting } from '../../../../addons/utfpr_components/src/assets/icons/buttons/IconDocumentWriting';
import { IconDesktopDevice } from '../../../../addons/utfpr_components/src/assets/icons/buttons/IconDesktopDevice';
import { urlHandler } from '../../../../addons/utfpr_components/src/helpers/urlHandler';
import Loading from 'addons/utfpr_components/src/components/Loading/Loading';
const ListingDocuments = ({ items, linkTitle, linkHref, isEditMode }) => {
  function getItemLink(item) {
    const url = urlHandler(getItemUrl(item));
    if (isFile(item)) {
      const downloadLink = url + '/@@download/file';
      return downloadLink;
    }
    return url;
  }

  const [itemsList, setItemsList] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(()=>{
    let itemsProcessed = items.map((item) => {
      return (
        <a
          key={item['@id']}
          className="w-full rounded-lg justify-between hover:border-[#005DB7] dark:hover:border-[#FCBC00] border border-solid cursor-pointer shadow p-5 group flex flex-row xl:items-center gap-2 xl:gap-[40px] max-xl:flex-col"
          href={getItemLink(item)}
          target={isFile(item) ? '_self' : '_blank'}
        >
          <div className="flex items-center gap-2">
            <div>
              {item['@type'] === 'Link' && item.description ? (
                <IconDesktopDevice
                  size="24"
                  color="#000000"
                  className="group-hover:[&_path]:stroke-[#005DB7] dark:group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-white min-w-max"
                />
              ) : item['@type'] === 'File' &&
                item.id?.includes('calendario') &&
                'default value' ? (
                <div className="w-[24px]">
                  <IconDownload
                    size="24"
                    color="#000000"
                    className="text-black dark:text-white dark:[&_path]:stroke-white dark:group-hover:text-[#FCBC00] group-hover:text-[#005DB7] min-w-max"
                  />
                </div>
              ) : item.id?.includes('calendario') && 'default value' ? (
                <div>
                  <IconDiagonalTopRightArrow
                    size="24"
                    color="#000000"
                    className=" group-hover:[&_path]:stroke-[#005DB7] dark:[&_path]:stroke-white min-w-max"
                  />
                </div>
              ) : item.mime_type === 'application/pdf' ||
                item.mime_type === 'text/plain' ? (
                <IconDocumentWriting
                  size="24"
                  color="#000000"
                  className="group-hover:[&_path]:stroke-[#005DB7] dark:group-hover:[&_path]:stroke-[#FCBC00] dark:[&_path]:stroke-white min-w-max"
                />
              ) : null}
            </div>
            <div className="ml-3 dark:text-white  text-[16px] group-hover:text-[#005DB7] dark:group-hover:text-[#FCBC00] leading-6 tracking-wider font-medium w-full">
              {item.title}
            </div>
          </div>

          <div className="flex items-center gap-2 xl:min-w-max">
            <div className="text-[12px] group-hover:text-[#005DB7] dark:group-hover:text-[#FCBC00] text-[#8D9199]">
              {getFileType(item).toLocaleUpperCase()}
            </div>
            <div className="flex items-center mx-1 text-[14px] whitespace-nowrap dark:group-hover:text-[#FCBC00] group-hover:text-[#005DB7] text-center text-[#8D9199]">
              {item.getObjSize}
            </div>
            {item['@type'] === 'Link' && item.description ? (
              <div className="text-[12px] group-hover:text-[#005DB7] dark:group-hover:text-[#FCBC00] text-[#8D9199] w-[300px]">
                {item.description}
              </div>
            ) : (
              <>
                {item.Date ? (
                  <>
                    <div className="flex items-center ">
                      <div className="text-[14px] mx-1 w-full group-hover:text-[#005DB7] dark:group-hover:text-[#FCBC00]  text-[#8D9199]">
                        Modificação em
                      </div>
                    </div>
                    <div className="text-[14px]  dark:group-hover:text-[#FCBC00] group-hover:text-[#005DB7] text-[#8D9199]">
                      {formatDate(item.Date, 'dd/MM/yyyy HHhmm')}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </a>
      );
    });
    setLoading(false);
    setItemsList(itemsProcessed);
  }, [])

  return (
    <div className="w-full flex flex-col gap-4 items-center pb-5">
      {loading == true ? <Loading></Loading> : null}
      {itemsList}
    </div>
  );
};

/**
 *
 * @param {object} item
 * @param {string} [item.url]
 * @param {string} [item.getUrl]
 * @returns {string}
 */
function getFileType(item) {
  switch (item['@type']) {
    case 'Link':
      return 'externo';
    case 'utf.edital':
      return 'edital';
    case 'Document':
      return 'página';
  }

  const urlChuncks = getItemUrl(item).split('.');

  if (urlChuncks.length == 2) return urlChuncks[1];

  return 'docx';
}

/**
 *
 * @param {object} item
 * @returns {boolean}
 */
function isFile(item) {
  if (!item) return false;
  return item['@type'] == 'File';
}

/**
 * @param {object} item
 * @param {string} [item.url]
 * @param {string} [item.getURL]
 * @returns {string}
 */
function getItemUrl(item) {
  const { url, getURL } = item;
  if (url) return url;

  if (getURL) return getURL;
  return '';
}

export default ListingDocuments;
