//@ts-check
import React, { useState } from 'react';
import { assertBaseDataTypes } from './assertBaseDataTypes';
import { Layouts } from './layout/layouts';
import { CardEdit as CompactCardEdit } from './layout/Compact/CardEdit';
import { CardEdit as CompleteCardEdit } from './layout/Complete/CardEdit';
//@ts-ignore
import { SidebarPortal, Field } from '@plone/volto/components';
import { CardsCarouselContainer } from './CardsCarouselContainer';
import { ListingContainer } from './ListingContainer';
import { SwiperSlide } from 'swiper/react';

import { compactSchema } from './layout/Compact/compactSchema';

export const ExtensionProjectCardsEdit = (props) => {
  const { onChangeBlock, block, selected } = props;

  const data = assertBaseDataTypes(props.data);

  /** unsafe function, use with caution */
  const onChange = (value, id) => {
    onChangeBlock(block, { ...data, [id]: value });
  };

  // cards setters and values

  const [cards, prototypeSetCard] = useState(data.cards);

  /**
   *
   * @param {unknown[]} cards
   */
  function setCards(cards) {
    const filter = cards.filter((card) => card !== undefined && card !== null);

    onChange(filter, 'cards');
    prototypeSetCard(filter);
  }

  // layout setter and values

  const [layout, prototypeSetlayout] = useState(data.layout);

  /**
   *
   * @param {Layouts} layout
   */
  function setLayout(layout) {
    onChange(layout, 'layout');
    prototypeSetlayout(layout);
  }

  return (
    <>
      {data.layout == Layouts.COMPACT ? (
        <CardsCarouselContainer allowTouchMove={false}>
          {cards.map((card, idx) => (
            <SwiperSlide key={idx}>
              <CompactCardEdit
                data={card}
                key={idx}
                setCard={(card) => {
                  cards[idx] = card;
                  setCards([...cards]);
                }}
              ></CompactCardEdit>
            </SwiperSlide>
          ))}
        </CardsCarouselContainer>
      ) : (
        <ListingContainer>
          {cards.map((card, idx) => (
            <CompleteCardEdit
              data={card}
              setCard={(card) => {
                cards[idx] = card;
                setCards([...cards]);
              }}
              key={idx}
            ></CompleteCardEdit>
          ))}
        </ListingContainer>
      )}

      <SidebarPortal selected={selected}>
        <div className="w-full mb-4">
          <label htmlFor="">
            layout
            <select
              onChange={(ev) => {
                setLayout(ev.target.value);
              }}
              defaultValue={layout}
              className="h-full w-full"
            >
              <option value={Layouts.COMPLETE}>completo</option>
              <option value={Layouts.COMPACT}>compacto</option>
            </select>
          </label>
        </div>
        <Field
          id="cards"
          widget="object_list"
          title="projetos de extensão"
          schema={compactSchema}
          value={cards}
          onChange={(id, value) => {
            if (!Array.isArray(value)) return;
            setCards([...value]);
          }}
        />
      </SidebarPortal>
    </>
  );
};
