export const tagSchema = {
  title: 'tag',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['tag'],
    },
  ],
  properties: {
    tag: {
      title: 'tag',
      type: 'string',
      widget: 'text',
    },
  },
};
