import React, { useState, useEffect } from 'react';
import Slider from './Slider.js';
import './UtfprNumbers.css';

const ViewUtfprNumbers = (props) => {
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [numbers, setNumbers] = useState([]);

  const getNumbersPage = () => {
    let numbersPages = Math.ceil(props.data.numbers.length / 3);
    setPages(numbersPages);
  };

  const constructNumberPages = () => {
    let baseNumbers = props.data.numbers;
    let arrPrimary = [];
    let arrPage = [];
    let aux = 0;

    baseNumbers.forEach((element) => {
      arrPage.push(element);

      if (aux == 2) {
        arrPrimary.push(arrPage);
        arrPage = [];
        aux = 0;
      } else {
        aux++;
      }
    });

    if (arrPage.length > 0) {
      arrPrimary.push(arrPage);
      arrPage = [];
    }

    console.log(arrPrimary);

    setNumbers(arrPrimary);
  };

  const nextPage = () => {
    if (activePage < pages) {
      setActivePage(activePage + 1);
    } else {
    }
  };

  const backPage = () => {
    if (activePage != 1) {
      setActivePage(activePage - 1);
    } else {
    }
  };

  useEffect(() => {
    if (typeof props.data.numbers != 'undefined') {
      getNumbersPage();
      constructNumberPages();
    }
  }, [props.data.numbers]);

  return (
    <div className="[&_.custom-container]:px-0 sm:px-0 sm:w-[612px] md:w-[740px] lg:w-[718px] xl:w-[974px] 2xl:w-[1230px] transition-[0.5s] mx-auto">
      <h1 className="w-full flex flex-row justify-center pb-[50px]">
        UTFPR em Números
      </h1>

      <div className="container-cards flex flex-row w-full justify-between align-middle ">
        {numbers.length > 0 ? (
          <>
            <div
              onClick={() => {
                backPage();
              }}
              className="flex flex-row justify-center items-center"
            >
              <svg
                width="20"
                height="29"
                viewBox="0 0 15 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.82 21.18L5.66 12L14.82 2.82L12 5.76437e-10L5.7703e-10 12L12 24L14.82 21.18Z"
                  fill="#BFBBBB"
                />
              </svg>
            </div>

            <div className="cards flex md:flex-row justify-center gap-10 flex-col">
              {typeof numbers[activePage - 1] != 'undefined' ? (
                numbers[activePage - 1].map((number) => (
                  <div className="flex p-3 w-[344px] h-[208px] flex-col border-[1px] border-[#DAD8D8] rounded-[10px] shadow-lg justify-center item-center xl:w-[273px] lg:w-[202px] md:w-[207px]">
                    <h2 className="flex flex-row justify-center text-[#005DB7]">
                      {number.number}
                    </h2>
                    <h3 className="subtitleUtfprNumbers flex flex-row justify-center text-[#005DB7]">
                      {number.title}
                    </h3>
                    <p className="w-full max-h-[50px] text-ellipsis text-wrap overflow-hidden justify-center text-center dark:text-white">
                      {number.desc}
                    </p>
                  </div>
                ))
              ) : (
                <>Nenhum item nessa página!</>
              )}
            </div>
            <div
              onClick={() => {
                nextPage();
              }}
              className="flex flex-row justify-center items-center"
            >
              <svg
                width="20"
                height="29"
                viewBox="0 0 15 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.179688 21.18L9.33969 12L0.179688 2.82L2.99969 1.35463e-10L14.9997 12L2.99969 24L0.179688 21.18Z"
                  fill="#BFBBBB"
                />
              </svg>
            </div>
          </>
        ) : (
          <div className="text-center w-full">Nenhum número cadastrado!</div>
        )}
      </div>

      <div className="queue flex flex-row w-full justify-center gap-[12px] pt-[50px]">
        <Slider pagesNumber={pages} activePage={activePage}></Slider>
      </div>
    </div>
  );
};

export default ViewUtfprNumbers;
