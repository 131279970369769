//@ts-check
import React, { useState } from 'react';

/** @type {React.FC<{url:string}>} */
export const ImageContainer = ({ url }) => {
  const [loaded, setLoaded] = useState(true);
  return (
    <img
      src={url}
      onLoadStart={() => {
        console.debug('loading');
        setLoaded(false);
      }}
      onLoad={() => {
        console.debug('loaded');
        setLoaded(true);
      }}
      loading="lazy"
      className={`w-full object-cover ${
        loaded ? '' : 'aspect-video animate-pulse bg-gray-500/50'
      }`}
    ></img>
  );
};
