import React, { useContext, useState, useEffect } from 'react';
import { FiClock } from 'react-icons/fi';
import { getFullDate } from 'helpers/Utils/Utils';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { ImagePlaceholder } from '../../../../addons/utfpr_components/src/assets/icons/buttons/ImagePlaceholder';
import { CustomContainer } from 'addons/utfpr_components/src/components/general';
import Loading from 'addons/utfpr_components/src/components/Loading/Loading';

const ImagesNewsTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  function openLink(link) {
    const handledUrl = urlHandler(link);
    window.location.href = handledUrl;
  }

  const [itemsList, setItemsList] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(()=>{
    let itemsProcessed = items.map((item, index) => {
      const baseUrl = item['@id'].replace(/"/g, ''); // Remove as aspas da URL base
      const imageUrl = item.image_scales?.image?.[0]?.download.replace(
        /"/g,
        '',
      ); // Remove as aspas da URL da imagem
      const fullImageUrl = baseUrl + '/' + imageUrl; // Concatena as URLs

      return (
        <div
          key={item['@id']}
          className="w-full flex flex-col cursor-pointer group"
          onClick={() => {
            openLink(item['@id']);
          }}
        >
          <div className="w-full group dark:text-white dark:bg-[#001C3A] flex  justify-start border-gray-400 overflow-hidden rounded shadow">
            <div className="w-full group flex flex-col gap-2 p-6">
              <div className="text-[22px] font-bold group-hover:text-[#005DB7]">
                {item.title}
              </div>
              {item.CreationDate && (
                <div className="text-[#8D9199] text-[14px] flex flex-row gap-2 items-center ">
                  <FiClock className="text-[14px]" />
                  <div className="text-sm text-[#8D9199] font-normal leading-5">
                    Publicado em{' '}
                    {new Date(item.created).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                    , última modificaçao{' '}
                    {new Date(item.modified).toLocaleString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </div>
                </div>
              )}
              <div className="text-base font-normal leading-6 tracking-wide">
                {item.description}
              </div>
            </div>
            <div
              key={index}
              className="flex flex-col items-start w-[240px] min-h-[240px] space-y-2.5"
            >
              {item.image_scales &&
              item.image_scales.image &&
              item.image_scales.image[0] &&
              item.image_scales.image[0].scales &&
              item.image_scales.image[0].scales.preview ? (
                <img
                  src={fullImageUrl}
                  alt={item.title}
                  className="w-[270px] min-w-[270px] h-full object-cover"
                />
              ) : (
                <div className=" w-[270px] min-w-[270px] h-full flex items-center justify-center">
                  <ImagePlaceholder
                    color="#000000"
                    className="dark:[&_path]:stroke-[#FFFFFF] group-hover:[&_path]:stroke-[#005DB7]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    setLoading(false);
    setItemsList(itemsProcessed);
  }, [])

  return (
    <CustomContainer
      className="w-full flex flex-col gap-3 mx-auto items-center pb-5"
      size="ensino-institucional"
      alignCenter={true}
    >
      {' '}
      {loading == true ? <Loading></Loading> : null}
      {itemsList}
    </CustomContainer>
  );
};

export default ImagesNewsTemplate;
