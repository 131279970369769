import React from 'react';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { RenderBlocks } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { CustomContainer } from 'addons/utfpr_components/src/components/general';

const GridBlockView = (props) => {
  const { data, path, className } = props;
  const metadata = props.metadata || props.properties;
  const columns = data.blocks_layout.items;
  const blocksConfig =
    config.blocks.blocksConfig[data['@type']].blocksConfig ||
    props.blocksConfig;
  const location = {
    pathname: path,
  };

  const getGridColumnClass = (columns) => {
    if (!columns.length || columns.length === 1) {
      return 'grid-cols-1';
    } else if (columns.length === 2) {
      return 'grid-cols-2';
    } else if (columns.length === 3) {
      return 'grid-cols-3';
    } else if (columns.length === 4) {
      return 'grid-cols-4';
    } else {
      return 'grid-cols-1';
    }
  };

  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
      className={cx('', data['@type'], className, {
        one: columns?.length === 1,
        two: columns?.length === 2,
        three: columns?.length === 3,
        four: columns?.length === 4,
      })}
    >
      {data.headline && <h2 className="headline">{data.headline}</h2>}

      <div className={`grid ${getGridColumnClass(columns)} [&_.custom-container]:!w-full`}>
      <RenderBlocks
        {...props}
        blockWrapperTag={Grid.Column}
        metadata={metadata}
        content={data}
        location={location}
        blocksConfig={blocksConfig}
        isContainer
      />
    </div>
    </CustomContainer>
  );
};

export default withBlockExtensions(GridBlockView);
