//@ts-check
import React from 'react';

/**
 *
 * @param {{children:React.ReactNode}} param0
 */
export const TagsContainer = ({ children }) => {
  return (
    <>
      <p className="font-bold text-darkModeBg dark:text-gray-50">
        Projetos e linhas de pesquisa
      </p>
      <div className="flex flex-row flex-wrap overflow-scroll gap-4">
        {children}
      </div>
    </>
  );
};
