//@ts-check
import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * @typedef CustomContainer
 * @prop {React.ReactNode} children
 * @prop {React.HTMLAttributes<HTMLDivElement>["className"]} className
 * @prop {boolean|undefined} alignCenter
 * @returns
 * @param {CustomContainer} props
 */
export const EnsinoInstitucionalContainer = (props) => {
  /**
   * sizes of :
   *      ensino : 74.6167px
   *      pesquisa : 100.917px
   *      inovação : 101.017px
   *      extensão : 103.067px
   *      institucional : 140.8px
   *      soma : 520.4177px
   *  width:
   *    sm: 640px - (16px *2)
   *    md: 768px - (16px *2)
   *    lg: 1024px - ((16px + 146px) *2)
   *    xl: 1280px - ((16px + 146px) *2)
   *    2xl: 1536px - ((16px + 146px) *2)
   */

  return (
    <div
      className={twMerge(
        'custom-container ensino-institucional-container w-full px-4 [&_.custom-container]:px-0 sm:px-0 sm:w-[612px] md:w-[740px] lg:w-[718px] xl:w-[974px] 2xl:w-[1230px] transition-[0.5s]',
        props.alignCenter && ' mx-auto ',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
