//@ts-check
import React from 'react';
//@ts-ignore
import { SidebarPortal } from '@plone/volto/components';
import { SocialMediaView } from './SocialMediaView';
import { SocialMediaData } from './SocialMediaData';

/**
 *
 * @typedef {import('./type').SocialMediaData} SocialMediaData
 *
 * @typedef {import('../general/type').VoltoBlockProps<SocialMediaData | undefined>} VoltoBlockProps
 *
 * @type {React.FC<VoltoBlockProps>}
 */
export const SocialMediaEdit = (props) => {
  const { selected } = props;

  return (
    <>
      <SocialMediaView {...props}></SocialMediaView>
      <SidebarPortal selected={selected}>
        <SocialMediaData {...props}></SocialMediaData>
      </SidebarPortal>
    </>
  );
};
