export const geralSchema = () => ({
  title: 'Edição Campi',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'title1',
        'title2',
        'href2',
        'title3',
        'title4',
        'href4',
        'title5',
        'title6',
        'href6',
        'title7',
        'title8',
        'href8',
        'title9',
        'title10',
        'href10',
        'title11',
        'title12',
        'href12',
        'title13',
        'title14',
        'href14',
        'title15',
        'title16',
        'href16',
        'title17',
        'title18',
        'href18',
        'title19',
        'title20',
        'href20',
        'title21',
        'title22',
        'href22',
        'title23',
        'title24',
        'href24',
        'title25',
        'title26',
        'href26',
      ],
    },
  ],
  properties: {
    title1: {
      title: 'Descrição - AP',
      widget: 'textarea',
    },
    title2: {
      title: 'Texto Link - AP',
      widget: 'link',
    },
    href2: {
      title: 'Link - AP:',
      widget: 'link',
    },
    title3: {
      title: 'Descrição - CM',
      widget: 'textarea',
    },
    title4: {
      title: 'Texto Link - CM',
      widget: 'link',
    },
    href4: {
      title: 'Link - CM:',
      widget: 'link',
    },
    title5: {
      title: 'Descrição - CP',
      widget: 'textarea',
    },
    title6: {
      title: 'Texto Link - CP',
      widget: 'link',
    },
    href6: {
      title: 'Link - CP:',
      widget: 'link',
    },
    title7: {
      title: 'Descrição - CT',
      widget: 'textarea',
    },
    title8: {
      title: 'Texto Link - CT',
      widget: 'link',
    },
    href8: {
      title: 'Link - CT:',
      widget: 'link',
    },
    title9: {
      title: 'Descrição - DP',
      widget: 'textarea',
    },
    title10: {
      title: 'Texto Link - DP',
      widget: 'link',
    },
    href10: {
      title: 'Link - DP:',
      widget: 'link',
    },
    title11: {
      title: 'Descrição - FB',
      widget: 'textarea',
    },
    title12: {
      title: 'Texto Link - FB',
      widget: 'link',
    },
    href12: {
      title: 'Link - FB:',
      widget: 'link',
    },
    title13: {
      title: 'Descrição - GP',
      widget: 'textarea',
    },
    title14: {
      title: 'Texto Link - GP',
      widget: 'link',
    },
    href14: {
      title: 'Link - GP:',
      widget: 'link',
    },
    title15: {
      title: 'Descrição - LD',
      widget: 'textarea',
    },
    title16: {
      title: 'Texto Link - LD',
      widget: 'link',
    },
    href16: {
      title: 'Link - LD:',
      widget: 'link',
    },
    title17: {
      title: 'Descrição - MD',
      widget: 'textarea',
    },
    title18: {
      title: 'Texto Link - MD',
      widget: 'link',
    },
    href18: {
      title: 'Link - MD:',
      widget: 'link',
    },
    title19: {
      title: 'Descrição - PB',
      widget: 'textarea',
    },
    title20: {
      title: 'Texto Link - PB',
      widget: 'link',
    },
    href20: {
      title: 'Link - PB:',
      widget: 'link',
    },
    title21: {
      title: 'Descrição - PG',
      widget: 'textarea',
    },
    title22: {
      title: 'Texto Link - PG',
      widget: 'link',
    },
    href22: {
      title: 'Link - PG:',
      widget: 'link',
    },
    title23: {
      title: 'Descrição - SH',
      widget: 'textarea',
    },
    title24: {
      title: 'Texto Link - SH',
      widget: 'link',
    },
    href24: {
      title: 'Link - SH:',
      widget: 'link',
    },
    title25: {
      title: 'Descrição - TD',
      widget: 'textarea',
    },
    title26: {
      title: 'Texto Link - TD',
      widget: 'link',
    },
    href26: {
      title: 'Link - TD:',
      widget: 'link',
    },
  },
  required: [
    'title2',
    'href2',
    'title4',
    'href4',
    'title6',
    'href6',
    'title8',
    'href8',
    'title10',
    'href10',
    'title12',
    'href12',
    'title14',
    'href14',
    'title16',
    'href16',
    'title18',
    'href18',
    'title20',
    'href20',
    'title22',
    'href22',
    'title24',
    'href24',
    'title26',
    'href26',
  ],
});
