const PostItCursoLayout = (props) => {
  return (
    <div
      style={{ backgroundColor: props.color }}
      className="font-dsBody p-7 flex flex-col md:flex-row justify-between"
    >
      <div className="md:w-1/2 flex flex-col gap-y-2 md:gap-y-10">
        <div>
          <p style={{ fontWeight: 500 }}>Duração:</p>
          <p className="text-xl sm:text-2xl text-left" style={{ fontWeight: 700 }}>
            {props.text}
          </p>
        </div>

        <div className="flex flex-col">
          <p style={{ fontWeight: 700 }}>PRESENCIAL</p>
          <p style={{ fontWeight: 400 }} className="!text-left">{props.title}</p>
          <p style={{ fontWeight: 400 }} className="!text-left">{props.fomento}</p>
          <p style={{ fontWeight: 400 }} className="!text-left">{props.campus}</p>
          <p style={{ fontWeight: 400 }} className="!text-left">{props.camp}</p>
          <p style={{ fontWeight: 400 }} className="!text-left">{props.campi}</p>
        </div>
      </div>

      <div className="md:w-1/2 flex flex-col gap-y-10 md:justify-between">
        <div className="flex flex-col mt-2 md:mt-0">
          <div className="flex gap-x-2">
            <p style={{ fontWeight: 700 }}>Regime:</p>
            <p style={{ fontWeight: 400 }} className="!text-left">{props.periodo}</p>
          </div>
          <div className="flex gap-x-2 !text-left">
            <p style={{ fontWeight: 700 }}>Turno:</p>
            <p style={{ fontWeight: 400 }} className="!text-left">{props.turno}</p>
          </div>
          <div className="flex gap-x-2 ">
            <p
              className="font-normal whitespace-normal break-normal  w-full justify-items !text-left"
              style={{ fontWeight: 400, textJustify: 'inter-word' }}
            >
              <strong>Titulação:</strong> {props.titulacao}
            </p>
          </div>

          <div className="flex gap-x-2">
            <p style={{ fontWeight: 700 }}>Pontuação:</p>
            <p style={{ fontWeight: 400 }} className="!text-left">{props.pontuacao}</p>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full">
          {props.labels !== undefined &&
            props.labels.length > 0 &&
            props.labels.map((label, index) => {
              return (
                <p
                  className="w-full text-center border-solid border-black border py-1 px-6 rounded-3xl"
                  style={{ fontWeight: 500 }}
                  key={index}
                >
                  {label.text}
                </p>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PostItCursoLayout;
