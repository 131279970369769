//@ts-check
import React, { useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  EffectFade,
  Autoplay,
  Pagination,
} from 'swiper/core';
import Item from './Item';
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

import { isImageObjectBrowser } from '../../helpers/isImageObjectBrowser.js';

SwiperCore.use([Navigation, EffectFade, Autoplay, Pagination]);

const View = (props) => {
  const urlImageHandler = useCallback((url) =>
    isInternalURL(url) ? `${flattenToAppURL(url)}/@@images/image` : url, []);
  const urlHandler = useCallback((url) => (isInternalURL(url) ? flattenToAppURL(url) : url), []);
  const { data } = props;
  const { files } = data;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span className="${className} border border-black !bg-[#FFC800] shadow !w-[13px] !h-[13px]"></span>`;
    },
  };

  //console.log('props.token', props.token);
  //console.log('props.editable', props.editable);

  return (
    <div
      className="!w-full !mx-auto overflow-hidden banner"
    >
      <div
        className="w-full relative
        sm:[&_div.swiper-button-prev]:!w-24
        [&_div.swiper-button-prev]:!w-14
        sm:[&_div.swiper-button-next]:!w-24
        [&_div.swiper-button-next]:!w-14
        smoother-swiper"
      >
        {files && files.length > 0 ? (
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation={files.length > 1 ? true : false}
            loop={files.length > 1 ? true : false}
            effect={'fade'}
            autoplay={
              props.editable
                ? false
                : {
                  delay: 4000,
                  disableOnInteraction: true,
                }
            }
            style={{}}
            autoHeight={true}
            pagination={pagination}
          >
            {files.map((file, idx) => {
              const isImage = isImageObjectBrowser(file);

              const link = isImage
                ? urlHandler(data[`imageLink${idx}`])
                : urlHandler(file.getURL);

              let title = isImage ? data[`imageTitle${idx}`] : file.title;

              if (!title || data[`hide_title_${idx}`]) title = '';

              return (
                <SwiperSlide key={file.getURL + idx} >
                  <Item
                    key={file.getURL + idx}
                    title={title}
                    img={urlImageHandler(file.getURL)}
                    link={link}
                    verticalOffset={data[`imageVerticalOffset${idx}`]}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="flex h-[450px] justify-center items-center">
            Sem notícias
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
