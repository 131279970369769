import React, { Component } from 'react';
import { Helmet } from '@plone/volto/helpers';
import editorImage from './assets/editor.jpeg';
import estudanteImage from './assets/estudante.jpeg';

import qs from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { CustomContainer } from '../../../../addons/utfpr_components/src/components/general';
import { LoginCard } from './LoginCard';

/*
sorry for using a react class component here, but the 'react-intl' package in this version can only work with class components.
i tried to create a connection with react function components so its easier and more maintanable to deal with this code.
*/
/**
 *
 */
class LoginMenu extends Component {
  pathname = '';
  constructor(props) {
    super(props);
    this.pathname = this.props.location.pathname;
  }

  getPathToLogin() {
    const parts = this.pathname.split('/');

    parts.pop();

    parts.push('login');

    return parts.join('/');
  }

  render() {
    return (
      <>
        <Helmet title={'Login Menu'} />
        <CustomContainer
          size="ensino-institucional"
          alignCenter
          className="flex flex-col gap-11 pt-11 pb-20"
        >
          <h1 className="dark:text-dsGray-50">Login</h1>
          <div className="flex flex-col md:flex-row justify-center gap-10">
            <LoginCard
              description="Entre no sistema acadêmico para visualizar suas notas, disciplinas e informações de matrícula."
              title="Sou estudante"
              img={estudanteImage}
              to={'https://sistemas2.utfpr.edu.br/home'}
            ></LoginCard>
            <LoginCard
              title="Sou editor"
              description="Entre para gerenciar e atualizar o conteúdo do portal."
              img={editorImage}
              to={this.getPathToLogin()}
            ></LoginCard>
          </div>
        </CustomContainer>
      </>
    );
  }
}
export default compose(
  withRouter,
  injectIntl,
  connect((state, props) => ({
    error: state.userSession.login.error,
    loading: state.userSession.login.loading,
    token: state.userSession.token,
    returnUrl:
      qs.parse(props.location.search).return_url ||
      props.location.pathname
        .replace(/\/login-menu\/?$/, '')
        .replace(/\/logout\/?$/, '') ||
      '/',
  })),
)(LoginMenu);
