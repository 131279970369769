const PostItIniciacaoCientificaLayout = (props) => {
    return (
       <div 
       style={{ backgroundColor: props.color }}
       className="font-dsBody p-7 flex flex-col md:flex-row gap-y-2">
            <div className="md:w-1/2 justify-between flex flex-col">
                <div>
                    <p style={{ fontWeight: 500 }}>
                        Vagas:
                    </p>
                    <p className="text-xl sm:text-2xl text-left" style={{ fontWeight: 700 }}>{props.text}</p>
                </div>

                <div className="flex flex-col mt-2 self-start">
                        <p style={{ fontWeight: 700 }}>
                            ORIENTADOR(A)
                        </p>
                        <p className="!text-left">{props.orientador}</p>
                </div>
            </div>

            <div className="md:w-1/2 flex flex-col">
                    <div className="flex gap-x-2">
                        <p style={{ fontWeight: 700 }}>
                            Duração:
                        </p>
                        <p className={"!text-left"}>{props.periodo}</p>
                    </div>
                    <div className="flex gap-x-2">
                        <p style={{ fontWeight: 700 }}>
                            Carga horária:
                        </p>
                        <p className={"!text-left"}>{props.carga}</p>
                    </div>
                
                
                    {props.sdg && <div className="mt-10 grid gap-2 gap-y-4 md:gap-y-2 sm:grid-rows-2 grid-cols-[repeat(auto-fill,minmax(60px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(40px,1fr))]">
                        {props.sdg.map((icon, index) => {
                            return <img key={index} className="h-16 w-16" src={icon.icon} />
                        })}
                    </div>}
                
            </div>
        </div>

    );
};

export default PostItIniciacaoCientificaLayout;