//@ts-check
import React from 'react';
import { CustomLink } from '../../../../addons/utfpr_components/src/components/general/CustomLink/CustomLink';

/** @type {React.FC<{title:string,description:string,to:string,img:string}>} */
export const LoginCard = (props) => {
  return (
    <CustomLink
      href={props.to}
      className="group w-full max-w-[384px] h-full max-h-[444px] grid md:grid-rows-2 grid-cols-1 hover:shadow-2xl shadow-md dark:hover:shadow-slate-500/10 bg-[#F8FAFA] dark:bg-[#001B3D] rounded-[1.2rem] overflow-hidden transition duration-300"
    >
      <img
        src={props.img}
        loading="lazy"
        className="object-cover h-full w-full max-sm:h-[200px]"
      />
      <div className="p-6 flex flex-col">
        <div className="flex flex-col gap-8 max-xl:gap-6">
          <h4 className="font-bold text-3xl text-black dark:text-white">{props.title}</h4>
          <p className="text-base font-normal h-[41px] text-black dark:text-white max-xl:h-[60px]">{props.description}</p>
          <button className="p-3 text-xl font-bold transition-all duration-300 rounded-full border-2 text-[#005DB7] border-[#005DB7] group-hover:text-[#FCBC00] group-hover:border-[#FCBC00] dark:text-[#A9C7FF] dark:border-[#A9C7FF] dark:group-hover:text-[#FCBC00] dark:group-hover:border-[#FCBC00]">
            Acessar
          </button>
        </div>
      </div>
    </CustomLink>
  );
};