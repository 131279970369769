//@ts-check
import React from 'react';
import { CustomContainer } from '../general';
/** @type {React.FC<{children:React.ReactNode}>} */
export const ListingContainer = (props) => {
  return (
    <CustomContainer
      size="ensino-institucional"
      className="flex flex-col gap-4"
      alignCenter={true}
    >
      {props.children}
    </CustomContainer>
  );
};
