//@ts-check
/**
 * @import {CommonExtensionCardData} from "../assertCardCommonDataTypes";
 * @typedef { CommonExtensionCardData & {subscribe:string} } CardDataType
 */

import { assertCardCommonDataTypes } from '../assertCardCommonDataTypes';

/**
 *
 * @param {unknown} data
 *
 * @returns {CardDataType}
 */
export function assertCardDataTypes(data) {
  const commonData = assertCardCommonDataTypes(data);

  return {
    subscribe:
      typeof commonData['subscribe'] == 'string' ? commonData['subscribe'] : '',
    ...commonData,
  };
}
