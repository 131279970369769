//@ts-check
import React from 'react';

/** @type {React.FC<{url:string}>} */
export const ImageContainer = ({ url }) => {
  return (
    <img
      src={url}
      loading="lazy"
      className="w-full aspect-video object-cover"
    ></img>
  );
};
