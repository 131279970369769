//@ts-check
import React, { useRef, useEffect } from 'react';
/**
 * @typedef {object} AutoHeightTextAreaProps
 * @prop {React.ChangeEventHandler<HTMLTextAreaElement> | undefined} onChange
 * @prop {React.HtmlHTMLAttributes<HTMLTextAreaElement>["className"] |undefined} className
 * @prop {string|undefined} value
 * @prop {string|undefined} placeholder
 */
/**
 *  @type {React.FC<AutoHeightTextAreaProps>} */
export const AutoHeightTextArea = (props) => {
  /** @type {React.MutableRefObject<null|HTMLTextAreaElement>} */
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '0px';

      const height = ref.current.scrollHeight;
      ref.current.style.height = `${height}px`;
    }
  });

  return (
    <textarea
      onChange={props.onChange}
      ref={ref}
      className={`resize-none ${props.className}`}
      value={props.value}
      placeholder={props.placeholder}
    ></textarea>
  );
};
