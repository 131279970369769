//@ts-check

/**
 *
 * @enum {string}
 * @readonly
 */
export const Layouts = {
  /**@readonly */
  COMPLETE: 'complete',
  /**@readonly */
  COMPACT: 'compact',
};
