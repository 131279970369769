//@ts-check
import React from 'react';

/** @type {React.FC<{children:React.ReactNode}>} */
export const CardContainer = ({ children }) => {
  return (
    <div className="flex flex-col gap-4 dark:[&_*]:text-gray-50 [&_span]:text-[16px] h-full shadow-lg shadow-gray-600/50 rounded-2xl overflow-hidden">
      {children}
    </div>
  );
};
