//@ts-check
import React from 'react';

/** @type {React.FC<{children:React.ReactNode}>} */
export const Responsible = ({ children }) => {
  return (
    <div className="flex flex-row gap-4 items-center [&_*]:text-[#8D9199]">
      <span className="text-base">Responsavél:</span> {children}
    </div>
  );
};
