import React from 'react';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { CustomContainer } from '../general';

const ImagesView = (props) => {

  const urlHandler = (url) => (isInternalURL(url) ? `${flattenToAppURL(url)}/@@images/image` : url);

  const { data } = props;
  const containerSizeValue = data.container ?? 'ensino-institucional';

  const AllSourcesExists = (n) => {
    let result;
    if (n !== 'all') {
      result = [];
      if (data.images && data.images.length >= n) {
        data.images.forEach((img, index) => {
          if (index < n) {
            if (img.src) {
              result.push(true);
            } else {
              result.push(false);
            }
          }
        })
      } else {
        result.push(false);
      }
    } else {
      result = []
      if (data.images && data.images.length > 0) {
        data.images.forEach((img) => {
          if (img.src) {
            result.push(true);
          } else {
            result.push(false);
          }
        })
      } else {
        result.push(false);
      }
    }
    return !result.includes(false);
  }

  if (data.layout == '2 imagens') {
    const srcCheck = AllSourcesExists(2)
    if (!srcCheck) {
      return (
        <div className="border-2 border-solid border-black p-2">
          <p>Para layout do tipo '2 imagens', insira imagens até completar no mínimo 2 imagens no total</p>
        </div>
      )
    }
    else {
      return (
        <CustomContainer size={containerSizeValue} alignCenter className={`gap-6 grid grid-rows-2 grid-cols-2 items-stretch justify-items-stretch h-[661px] overflow-hidden`}>
          {typeof data.images[0].src[0] != "undefined" ? <img src={urlHandler(data.images[0].src[0].getURL)} style={{
            objectFit: data.images[0].resize ?? 'fill',
            justifySelf: data.images[0].justifySelf ?? 'stretch',
            alignSelf: data.images[0].alignSelf ?? 'stretch',
          }} className={`col-start-1 col-end-2 row-start-1 row-end-2 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[1].src[0] != "undefined" ? <img src={urlHandler(data.images[1].src[0].getURL)} style={{
            objectFit: data.images[1].resize ?? 'fill',
            justifySelf: data.images[1].justifySelf ?? 'stretch',
            alignSelf: data.images[1].alignSelf ?? 'stretch'
          }} className={`col-start-2 col-end-3 row-start-2 row-end-3 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
        </CustomContainer>
      )
    }
  }

  if (data.layout == '3 imagens v1') {
    const srcCheck = AllSourcesExists(3)
    if (!srcCheck) {
      return (
        <div className={`border-2 border-solid border-black p-2 items-stretch`}>
          <p>Para layout do tipo "3 imagens v1", insira imagens até completar no mínimo 3 imagens no total</p>
        </div>
      )
    } else
      return (
        <CustomContainer size={containerSizeValue} alignCenter className={`grid grid-rows-2 grid-cols-2 gap-6 items-stretch justify-items-stretch h-[661px] overflow-hidden`}>
          {typeof data.images[0].src[0] != "undefined" ? <img src={urlHandler(data.images[0].src[0].getURL)} style={{
            objectFit: data.images[0].resize ?? 'fill',
            justifySelf: data.images[0].justifySelf ?? 'stretch',
            alignSelf: data.images[0].alignSelf ?? 'stretch',
          }} className={`col-start-1 col-end-2 row-start-1 row-end-3 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[1].src[0] != "undefined" ? <img src={urlHandler(data.images[1].src[0].getURL)} style={{
            objectFit: data.images[1].resize ?? 'fill',
            justifySelf: data.images[1].justifySelf ?? 'stretch',
            alignSelf: data.images[1].alignSelf ?? 'stretch',
          }} className={`col-start-2 col-end-3 row-start-1 row-end-2 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[2].src[0] != "undefined" ? <img src={urlHandler(data.images[2].src[0].getURL)} style={{
            objectFit: data.images[2].resize ?? 'fill',
            justifySelf: data.images[2].justifySelf ?? 'stretch',
            alignSelf: data.images[2].alignSelf ?? 'stretch',
          }} className={`col-start-2 col-end-3 row-start-2 row-end-3 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
        </CustomContainer>
      )
  }

  if (data.layout == '3 imagens v2') {
    const srcCheck = AllSourcesExists(3)
    if (!srcCheck) {
      return (
        <div className={`border-2 border-solid border-black p-2 items-stretch`}>
          <p>Para layout do tipo "3 imagens v2", insira imagens até completar no mínimo 3 imagens no total</p>
        </div>
      )
    } else
      return (
        <CustomContainer size={containerSizeValue} alignCenter className={`grid grid-rows-3 grid-cols-2 gap-6 items-stretch justify-items-stretch h-[661px] overflow-hidden`}>
          {typeof data.images[0].src[0] != "undefined" ? <img src={urlHandler(data.images[0].src[0].getURL)} style={{
            objectFit: data.images[0].resize ?? 'fill',
            justifySelf: data.images[0].justifySelf ?? 'stretch',
            alignSelf: data.images[0].alignSelf ?? 'stretch',
          }} className={`col-start-1 col-end-2 row-start-1 row-end-2 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[1].src[0] != "undefined" ? <img src={urlHandler(data.images[1].src[0].getURL)} style={{
            objectFit: data.images[1].resize ?? 'fill',
            justifySelf: data.images[1].justifySelf ?? 'stretch',
            alignSelf: data.images[1].alignSelf ?? 'stretch',
          }} className={`col-start-1 col-end-2 row-start-2 row-end-4 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[2].src[0] != "undefined" ? <img src={urlHandler(data.images[2].src[0].getURL)} style={{
            objectFit: data.images[2].resize ?? 'fill',
            justifySelf: data.images[2].justifySelf ?? 'stretch',
            alignSelf: data.images[2].alignSelf ?? 'stretch',
          }} className={`col-start-2 col-end-3 row-start-1 row-end-4 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
        </CustomContainer>
      )
  }

  if (data.layout == '4 imagens') {
    const srcCheck = AllSourcesExists(4)
    if (!srcCheck) {
      return (
        <div className={`border-2 border-solid border-black p-2 items-stretch`}>
          <p>Para layout do tipo "4 imagens", insira imagens até completar no mínimo 4 imagens no total</p>
        </div>
      )
    } else
      return (
        <CustomContainer size={containerSizeValue} alignCenter className={`grid grid-rows-3 grid-cols-3 gap-6 items-stretch justify-items-stretch h-[661px] overflow-hidden`}>
          {typeof data.images[0].src[0] != "undefined" ? <img src={urlHandler(data.images[0].src[0].getURL)} style={{
            objectFit: data.images[0].resize ?? 'fill',
            justifySelf: data.images[0].justifySelf ?? 'stretch',
            alignSelf: data.images[0].alignSelf ?? 'stretch',
          }} className={`col-start-1 col-end-3 row-start-1 row-end-3 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[1].src[0] != "undefined" ? <img src={urlHandler(data.images[1].src[0].getURL)} style={{
            objectFit: data.images[1].resize ?? 'fill',
            justifySelf: data.images[1].justifySelf ?? 'stretch',
            alignSelf: data.images[1].alignSelf ?? 'stretch',
          }} className={`col-start-1 col-end-2 row-start-3 row-end-4 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[2].src[0] != "undefined" ? <img src={urlHandler(data.images[2].src[0].getURL)} style={{
            objectFit: data.images[2].resize ?? 'fill',
            justifySelf: data.images[2].justifySelf ?? 'stretch',
            alignSelf: data.images[2].alignSelf ?? 'stretch',
          }} className={`col-start-2 col-end-3 row-start-3 row-end-4 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
          {typeof data.images[3].src[0] != "undefined" ? <img src={urlHandler(data.images[3].src[0].getURL)} style={{
            objectFit: data.images[3].resize ?? 'fill',
            justifySelf: data.images[3].justifySelf ?? 'stretch',
            alignSelf: data.images[3].alignSelf ?? 'stretch',
          }} className={`col-start-3 col-end-4 row-start-1 row-end-4 overflow-hidden`} /> : <p>Defina o local onde a imagem está armazenada</p>}
        </CustomContainer>
      )
  }

  if (data.layout == 'Grid') {
    const srcCheck = AllSourcesExists('all')
    if (!srcCheck) {
      return (
        <div className={`border-2 border-solid border-black p-2 items-stretch`}>
          <p>Para layout do tipo "Grid", insira quantas imagens que quiser</p>
        </div>
      )
    } else {
      return (
        <CustomContainer size={containerSizeValue} alignCenter>
          <div style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${data.cols}, 1fr)`
          }} className={`overflow-hidden gap-6 justify-stretch justify-items-stretch items-stretch h-[661px]`}>
            {data.images.map((imgItem, index) => { return typeof imgItem.src[0] != "undefined" ? <img key={index} className="overflow-hidden" src={urlHandler(imgItem.src[0].getURL)} /> : <p>Defina o local onde a imagem está armazenada</p> }
            )}
          </div>
        </CustomContainer>
      )
    }
  }

  return (
    <div className="border-2 border-solid border-black p-2">
      <p className="font-bold">Configure as imagens e o seu layout</p>
    </div>
  )
}

export default ImagesView;
