//@ts-check
/**
 * @typedef {{title:string,text:string,img:string, tags:{tag:string}[],responsible:string,"@id":string}} CommonExtensionCardData
 */
/**
 * @param {unknown} data
 * @returns {CommonExtensionCardData}
 */
export function assertCardCommonDataTypes(data) {
  let title = '',
    text = '',
    img = '',
    /** @type {CommonExtensionCardData["tags"]} */
    tags = [],
    responsible = '',
    id = '',
    others = {};

  if (typeof data == 'object' && data) {
    others = data;
    if (data['title']) title = String(data['title']);
    if (data['img']) img = String(data['img']);
    if (data['text']) text = String(data['text']);
    if (Array.isArray(data['tags'])) {
      tags = data['tags'].filter((tag) => typeof tag['tag'] == 'string');
    }
    if (data['responsible']) responsible = String(data['responsible']);
    if (data['@id']) id = String(data['@id']);
  }

  return { img, text, title, tags, responsible, '@id': id, ...others };
}
/**
 * @exports CommonData
 */
