import React, { useState } from 'react';

const PostItPesquisaLayout = (props) => {
  const [title, setTitle] = useState(props.title);

  return (
    <div
      className="font-dsBody p-7 flex flex-col gap-y-10 w-[90%] sm:w-full"
      onClick={props.onClick}
      style={{ backgroundColor: props.color }}
    >
      <div className="flex flex-col md:flex-row gap-y-3">
        {props.editable ? (
          <input
            value={props.title}
            placeholder="titulo"
            className="md:w-3/4 font-bold text-xl sm:text-2xl bg-[#fff7]"
            onChange={(ev) => {
              setTitle(ev.target.value);
              props.onChangeBlock(props.block, {
                ...props,
                title: ev.target.value,
              });
            }}
          ></input>
        ) : (
          <p className="md:w-3/4 font-bold text-xl !text-left sm:text-2xl">
            {props.title}
          </p>
        )}

        <div className="flex md:flex-col gap-2">
          {props.labels !== undefined &&
            props.labels.length > 0 &&
            props.labels.map((label, index) => {
              return (
                <p
                  className="border-solid text-center border-black border py-1 px-6 rounded-3xl"
                  style={{ fontWeight: 500 }}
                  key={index}
                >
                  {label.text}
                </p>
              );
            })}
        </div>
      </div>
      <div></div>
      <div className="flex md:flex-row flex-col justify-between">
        <div className="flex flex-col ">
          <p className="font-bold ">ORIENTADOR(A)</p>
          {props.editable ? (
            <input
              value={props.text}
              placeholder="orientador"
              className=" bg-[#fff7]"
              onChange={(ev) => {
                setTitle(ev.target.value);
                props.onChangeBlock(props.block, {
                  ...props,
                  text: ev.target.value,
                });
              }}
            ></input>
          ) : (
            <p className="break-words">{props.text}</p>
          )}
        </div>

        <div className="flex flex-row gap-x-2 md:self-end">
          <p className="font-bold ">Fomento:</p>
          {props.editable ? (
            <input
              value={props.fomento}
              placeholder="orientador"
              className=" bg-[#fff7]"
              onChange={(ev) => {
                setTitle(ev.target.value);
                props.onChangeBlock(props.block, {
                  ...props,
                  fomento: ev.target.value,
                });
              }}
            ></input>
          ) : (
            <p className="break-words">{props.fomento}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostItPesquisaLayout;
