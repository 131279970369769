//@ts-check
import React from 'react';

/** @type {React.FC<{children?:React.ReactNode}>} */
export const TagsContainer = (props) => {
  return (
    <div className="flex flex-row gap-2 justify-start flex-wrap">
      {props.children}
    </div>
  );
};
