//@ts-check
import React from 'react';
import '../../../Table/css/completeTable.css';
import { alignToCssClass } from '../helpers/alignToCssClass';
import { CustomLink } from '../../../../general/CustomLink/CustomLink';
// uma simples renderização de texto
/**
 * @param {import("../../types").CellProps<"text">} props
 */
export const TextCellView = (props) => {
  const align = alignToCssClass(props.attributes.align);

  // um retorno para cada tipo de elemento
  switch (props.attributes.el) {
    case 'p':
      return (
        <p
          title={props.text}
          className={`flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-normal [&_.tabela-linha-media]:leading-relaxed [&_.tabela-linha-grande]:leading-loose w-[calc(100%-1rem)] p-4 mx-auto ${align}`}
        >
          <CustomLink
            href={props.attributes.link || ''}
            openInNewWindow={props.attributes.openNewWindow}
            className={`${!props.attributes.link && 'pointer-events-none'}`}
          >
            {props.text}
          </CustomLink>
        </p>
      );
    case 'h1':
      return (
        <h1
          title={props.text}
          className={`flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-normal[&_.tabela-linha-media]:leading-relaxed [&_.tabela-linha-grande]:leading-loose p-4 mx-auto ${align}`}
        >
          <CustomLink
            href={props.attributes.link || ''}
            openInNewWindow={props.attributes.openNewWindow}
            className={`${!props.attributes.link && 'pointer-events-none'}`}
          >
            {props.text}
          </CustomLink>
        </h1>
      );
    case 'h2':
      return (
        <h2
          title={props.text}
          className={`flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-normal [&_.tabela-linha-media]:leading-relaxed [&_.tabela-linha-grande]:leading-loose p-4 mx-auto ${align}`}
        >
          <CustomLink
            href={props.attributes.link || ''}
            openInNewWindow={props.attributes.openNewWindow}
            className={`${!props.attributes.link && 'pointer-events-none'}`}
          >
            {props.text}
          </CustomLink>
        </h2>
      );
  }
  // caso algum tipo de elemento seja settado errado
  return (
    <p
      title={props.text}
      className={`flex flex-col justify-center h-full word-break-break-word [&_.tabela-linha-pequena]:leading-normal [&_.tabela-linha-media]:leading-relaxed [&_.tabela-linha-grande]:leading-loose w-[calc(100%-1rem)] p-4 mx-auto ${align}`}
    >
      <CustomLink
        href={props.attributes.link || ''}
        openInNewWindow={props.attributes.openNewWindow}
        className={`${!props.attributes.link && 'pointer-events-none'}`}
      >
        {props.text}
      </CustomLink>
    </p>
  );
};
