import {useState, useEffect} from 'react';
import "./style.css";
const Loading = ()=>{

    const [Loading, setLoading] = useState(1);

    useEffect(()=>{
        let loadingAux = 1;
        setInterval(()=>{
            if(loadingAux == 8){
                setLoading(1);
                loadingAux = 1;
            }else{
                setLoading(loadingAux+1);
                loadingAux++;
            }
        }, 150)
    },[])

    return(<>
        <div className='w-full flex justify-center p-5'>
        {(Loading == 1) ? (
            <svg width="84" height="85" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="65.2969" cy="66.949" r="7.66478" transform="rotate(45 65.2969 66.949)" fill="#EEEEEE"/>
            <circle cx="40.4212" cy="76.8825" r="7.66478" transform="rotate(90 40.4212 76.8825)" fill="#EEEEEE"/>
            <circle cx="65.9531" cy="17.5241" r="7.66478" transform="rotate(45 65.9531 17.5241)" fill="#EEEEEE"/>
            <circle cx="75.8352" cy="42.3951" r="7.66478" transform="rotate(90 75.8352 42.3951)" fill="#EEEEEE"/>
            <circle cx="17.3828" cy="17.7506" r="7.66478" transform="rotate(45 17.3828 17.7506)" fill="#EEEEEE"/>
            <circle cx="41.3274" cy="8.21254" r="7.66478" transform="rotate(90 41.3274 8.21254)" fill="#005DB7" className="circleLoading"/>
            <circle cx="17.1953" cy="65.0131" r="7.66478" transform="rotate(45 17.1953 65.0131)" fill="#EEEEEE"/>
            <circle cx="7.77272" cy="41.4966" r="7.66478" transform="rotate(90 7.77272 41.4966)" fill="#EEEEEE"/>
            </svg>            
        ):(<></>)}
        {(Loading == 2) ? (
            <svg width="84" height="85" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="65.2891" cy="66.949" r="7.66478" transform="rotate(45 65.2891 66.949)" fill="#EEEEEE"/>
            <circle cx="40.4133" cy="76.8825" r="7.66478" transform="rotate(90 40.4133 76.8825)" fill="#EEEEEE"/>
            <circle cx="65.9453" cy="17.5241" r="7.66478" transform="rotate(45 65.9453 17.5241)" fill="#005DB7" className="circleLoading"/>
            <circle cx="75.8274" cy="42.3951" r="7.66478" transform="rotate(90 75.8274 42.3951)" fill="#EEEEEE"/>
            <circle cx="17.375" cy="17.7506" r="7.66478" transform="rotate(45 17.375 17.7506)" fill="#EEEEEE"/>
            <circle cx="41.3196" cy="8.21254" r="7.66478" transform="rotate(90 41.3196 8.21254)" fill="#EEEEEE"/>
            <circle cx="17.1875" cy="65.0131" r="7.66478" transform="rotate(45 17.1875 65.0131)" fill="#EEEEEE"/>
            <circle cx="7.76491" cy="41.4966" r="7.66478" transform="rotate(90 7.76491 41.4966)" fill="#EEEEEE"/>
            </svg>                     
        ):(<></>)}
        {(Loading == 3) ? (
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="66.2812" cy="66.949" r="7.66478" transform="rotate(45 66.2812 66.949)" fill="#EEEEEE"/>
            <circle cx="41.4055" cy="76.8825" r="7.66478" transform="rotate(90 41.4055 76.8825)" fill="#EEEEEE"/>
            <circle cx="66.9375" cy="17.5241" r="7.66478" transform="rotate(45 66.9375 17.5241)" fill="#EEEEEE"/>
            <circle cx="76.8196" cy="42.3951" r="7.66478" transform="rotate(90 76.8196 42.3951)" fill="#005DB7" className="circleLoading"/>
            <circle cx="18.3672" cy="17.7506" r="7.66478" transform="rotate(45 18.3672 17.7506)" fill="#EEEEEE"/>
            <circle cx="42.3118" cy="8.21254" r="7.66478" transform="rotate(90 42.3118 8.21254)" fill="#EEEEEE"/>
            <circle cx="18.1797" cy="65.0131" r="7.66478" transform="rotate(45 18.1797 65.0131)" fill="#EEEEEE"/>
            <circle cx="8.7571" cy="41.4966" r="7.66478" transform="rotate(90 8.7571 41.4966)" fill="#EEEEEE"/>
            </svg>                                
        ):(<></>)}
        {(Loading == 4) ? (
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="66.2656" cy="66.949" r="7.66478" transform="rotate(45 66.2656 66.949)" fill="#005DB7" className="circleLoading"/>
            <circle cx="41.3899" cy="76.8825" r="7.66478" transform="rotate(90 41.3899 76.8825)" fill="#EEEEEE"/>
            <circle cx="66.9219" cy="17.5241" r="7.66478" transform="rotate(45 66.9219 17.5241)" fill="#EEEEEE"/>
            <circle cx="76.804" cy="42.3951" r="7.66478" transform="rotate(90 76.804 42.3951)" fill="#EEEEEE"/>
            <circle cx="18.3516" cy="17.7506" r="7.66478" transform="rotate(45 18.3516 17.7506)" fill="#EEEEEE"/>
            <circle cx="42.2962" cy="8.21254" r="7.66478" transform="rotate(90 42.2962 8.21254)" fill="#EEEEEE"/>
            <circle cx="18.1641" cy="65.0131" r="7.66478" transform="rotate(45 18.1641 65.0131)" fill="#EEEEEE"/>
            <circle cx="8.74147" cy="41.4966" r="7.66478" transform="rotate(90 8.74147 41.4966)" fill="#EEEEEE"/>
            </svg>                                          
        ):(<></>)}
        {(Loading == 5) ? (
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="66.2578" cy="66.949" r="7.66478" transform="rotate(45 66.2578 66.949)" fill="#EEEEEE"/>
            <circle cx="41.3821" cy="76.8825" r="7.66478" transform="rotate(90 41.3821 76.8825)" fill="#005DB7" className="circleLoading"/>
            <circle cx="66.9141" cy="17.5241" r="7.66478" transform="rotate(45 66.9141 17.5241)" fill="#EEEEEE"/>
            <circle cx="76.7962" cy="42.3951" r="7.66478" transform="rotate(90 76.7962 42.3951)" fill="#EEEEEE"/>
            <circle cx="18.3438" cy="17.7506" r="7.66478" transform="rotate(45 18.3438 17.7506)" fill="#EEEEEE"/>
            <circle cx="42.2883" cy="8.21254" r="7.66478" transform="rotate(90 42.2883 8.21254)" fill="#EEEEEE"/>
            <circle cx="18.1562" cy="65.0131" r="7.66478" transform="rotate(45 18.1562 65.0131)" fill="#EEEEEE"/>
            <circle cx="8.73366" cy="41.4966" r="7.66478" transform="rotate(90 8.73366 41.4966)" fill="#EEEEEE"/>
            </svg>                                                     
        ):(<></>)}
        {(Loading == 6) ? (
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="66.2539" cy="66.949" r="7.66478" transform="rotate(45 66.2539 66.949)" fill="#EEEEEE"/>
            <circle cx="41.3743" cy="76.8825" r="7.66478" transform="rotate(90 41.3743 76.8825)" fill="#EEEEEE"/>
            <circle cx="66.9062" cy="17.5241" r="7.66478" transform="rotate(45 66.9062 17.5241)" fill="#EEEEEE"/>
            <circle cx="76.7883" cy="42.3951" r="7.66478" transform="rotate(90 76.7883 42.3951)" fill="#EEEEEE"/>
            <circle cx="18.3359" cy="17.7506" r="7.66478" transform="rotate(45 18.3359 17.7506)" fill="#EEEEEE"/>
            <circle cx="42.2844" cy="8.21254" r="7.66478" transform="rotate(90 42.2844 8.21254)" fill="#EEEEEE"/>
            <circle cx="18.1484" cy="65.0131" r="7.66478" transform="rotate(45 18.1484 65.0131)" fill="#005DB7" className="circleLoading"/>
            <circle cx="8.72975" cy="41.4966" r="7.66478" transform="rotate(90 8.72975 41.4966)" fill="#EEEEEE"/>
            </svg>                                                                 
        ):(<></>)}
        {(Loading == 7) ? (
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="66.2422" cy="66.949" r="7.66478" transform="rotate(45 66.2422 66.949)" fill="#EEEEEE"/>
            <circle cx="41.3626" cy="76.8825" r="7.66478" transform="rotate(90 41.3626 76.8825)" fill="#EEEEEE"/>
            <circle cx="66.8945" cy="17.5241" r="7.66478" transform="rotate(45 66.8945 17.5241)" fill="#EEEEEE"/>
            <circle cx="76.7766" cy="42.3951" r="7.66478" transform="rotate(90 76.7766 42.3951)" fill="#EEEEEE"/>
            <circle cx="18.3242" cy="17.7506" r="7.66478" transform="rotate(45 18.3242 17.7506)" fill="#EEEEEE"/>
            <circle cx="42.2727" cy="8.21254" r="7.66478" transform="rotate(90 42.2727 8.21254)" fill="#EEEEEE"/>
            <circle cx="18.1367" cy="65.0131" r="7.66478" transform="rotate(45 18.1367 65.0131)" fill="#EEEEEE"/>
            <circle cx="8.71803" cy="41.4966" r="7.66478" transform="rotate(90 8.71803 41.4966)" fill="#005DB7" className="circleLoading"/>
            </svg>                                                                             
        ):(<></>)}
        {(Loading == 8) ? (
            <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="66.2305" cy="66.949" r="7.66478" transform="rotate(45 66.2305 66.949)" fill="#EEEEEE"/>
            <circle cx="41.3508" cy="76.8825" r="7.66478" transform="rotate(90 41.3508 76.8825)" fill="#EEEEEE"/>
            <circle cx="66.8828" cy="17.5241" r="7.66478" transform="rotate(45 66.8828 17.5241)" fill="#EEEEEE"/>
            <circle cx="76.7649" cy="42.3951" r="7.66478" transform="rotate(90 76.7649 42.3951)" fill="#EEEEEE"/>
            <circle cx="18.3125" cy="17.7506" r="7.66478" transform="rotate(45 18.3125 17.7506)" fill="#005DB7" className="circleLoading"/>
            <circle cx="42.261" cy="8.21254" r="7.66478" transform="rotate(90 42.261 8.21254)" fill="#EEEEEE"/>
            <circle cx="18.125" cy="65.0131" r="7.66478" transform="rotate(45 18.125 65.0131)" fill="#EEEEEE"/>
            <circle cx="8.70631" cy="41.4966" r="7.66478" transform="rotate(90 8.70631 41.4966)" fill="#EEEEEE"/>
            </svg>                                                                                         
        ):(<></>)}
        </div>
    </>)
}

export default Loading;