import React from 'react';
import NewsTabContent from './type/NewsTabContent';
import YoutubeTabContent from './type/YoutubeTabContent';

// ** Props **
// props.data.columnModeLimit: it can be positive, negative or fractional number

const View = (props) => {
  // In case of negative or decimal numbers, change it to positive integer
  const columnModeLimit = Math.trunc(
    Math.max(0, Math.abs(props.data.columnModeLimit)),
  );

  const dataType = props.data.type;

  switch (dataType) {
    case 'news':
      return (
        <NewsTabContent
          dataFiles={props.data.noticias}
          editable={Boolean(props.editable)}
          colModeLimit={columnModeLimit}
          token={props.token}
          showImages={props.data?.showImages ?? true}
        />
      );
    case 'media':
      return <YoutubeTabContent />;
    default:
      return (
        <div className="w-full h-[400px] flex justify-center items-center text-[20px] opacity-60">
          Escolha um tipo de conteúdo
        </div>
      );
  }
};

export default View;
