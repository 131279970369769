//@ts-check
import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * @typedef {"center"|"left"|"right"} Align
 * @typedef CustomContainer
 * @prop {React.ReactNode} children
 * @prop {React.HTMLAttributes<HTMLDivElement>["className"]} className
 * @prop {boolean|undefined} alignCenter
 * @returns
 * @param {CustomContainer} props
 */
export const PesquisaExtensaoContainer = (props) => {
  return (
    <div
      className={twMerge(
        'custom-container pesquisa-extensao-container w-full px-4 [&_.custom-container]:px-0 sm:px-0 sm:w-[350.79215px] md:w-[414.79215px] lg:w-[403.79215px] xl:w-[531.79215px] 2xl:w-[659.79215px] transition-[0.5s] ',
        props.alignCenter && ' mx-auto ',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
