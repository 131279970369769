//@ts-check
import React from 'react';
import { FacebookWindow } from './SocialMediaWindowTypes/FacebookWindow';
import { InstagramWindow } from './SocialMediaWindowTypes/InstagramWindow';
import { NotFoundWindow } from './SocialMediaWindowTypes/NotFoundWindow';
import { XWindow } from './SocialMediaWindowTypes/XWindow';
import { YoutubeWindow } from './SocialMediaWindowTypes/YoutubeWindow';

/**
 *
 * @param {{type:import('../type').SocialMediaWindowType,xPostLink:string,editable:boolean}} props
 */
export const SocialMediaWindow = (props) => {
  // render all components , even if they are hidden (facebookWindow and XWindow bugs if you don't do that)
  return (
    <div className="w-full h-full rounded-xl flex justify-center bg-slate-200 overflow-hidden">
      <NotFoundWindow
        hidden={
          props.type == 'facebook' ||
          props.type == 'instagram' ||
          props.type == 'x' ||
          props.type == 'youtube'
        }
      ></NotFoundWindow>
      <InstagramWindow hidden={props.type != 'instagram'}></InstagramWindow>
      <FacebookWindow hidden={props.type != 'facebook'}></FacebookWindow>
      <XWindow
        hidden={props.type != 'x'}
        postLink={props.xPostLink}
        editable={props.editable}
      ></XWindow>
      <YoutubeWindow hidden={props.type != 'youtube'}></YoutubeWindow>
    </div>
  );
};
