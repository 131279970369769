//@ts-check
import { Layouts } from './layout/layouts';
/**
 * @typedef {{cards:unknown[],layout:Layouts,"@type": "extensionProjectCards"}} BaseDataType
 */

/**
 * @param {unknown} data
 * @returns {BaseDataType}
 */
export function assertBaseDataTypes(data) {
  let cards = [],
    /**@type {Layouts} */
    layout = Layouts.COMPACT;

  if (typeof data != 'object' || !data)
    return { cards, layout, '@type': 'extensionProjectCards' };

  const blockType = data['@type'];

  if (Array.isArray(data['cards'])) cards = data['cards'];

  const layoutKeys = Object.keys(Layouts);

  for (const k of layoutKeys) {
    if (Layouts[k] == data['layout']) {
      layout = Layouts[k];

      break;
    }
  }

  return { cards, layout, '@type': blockType };
}
