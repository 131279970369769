import React from 'react';
import CampusLayout from './Campi';
import { CustomContainer } from '../general';

const ViewComponent = (props) => {

  const { data } = props;

  return (
    <CustomContainer
      size="pesquisa-extensao"
      className="mx-auto w-full flex flex-wrap gap-6 xl:w-[900px] 2xl:w-[900px]"
    >
      <CampusLayout
        {...data}
        editable={props.editable}
        onChangeBlock={props.onChangeBlock}
        block={props.block}
      ></CampusLayout>
    </CustomContainer>
  );

}


export default ViewComponent;
