//@ts-check
import React from 'react';
/**
 * @typedef {object} WarningContainerProps
 * @prop {React.ReactNode} children
 * @prop {React.HtmlHTMLAttributes<HTMLTextAreaElement>["className"] |undefined} className
 */

/** @type {React.FC<WarningContainerProps>} */
export const WarningContainer = (props) => {
  return (
    <div
      className={`border-2 border-solid border-[#ff8a00] text-[#ff8a00] bg-[#ffebaf] p-6 rounded-lg ${props.className}`}
    >
      {props.children}
    </div>
  );
};
