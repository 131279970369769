//@ts-check
import React from 'react';
import { assertCardDataTypes } from './assertCardDataTypes';
import { CardContainer } from '../CardContainer';
import { Responsible } from './Responsible';
import { ImageContainer } from './ImageContainer';
import { urlImageHandler } from '../../../../helpers/urlImageHandle';
import { CardSectionContainer } from '../CardSectionContainer';
import { Chip } from '../../../general';
import { TagsContainer } from './TagsContainer';
import { Constants } from './constants';

/**
 * @import {CardDataType} from "./assertCardDataTypes";
 * @param {{data:unknown}} props
 */
export const CardView = (props) => {
  const data = assertCardDataTypes(props.data);
  return (
    <CardContainer>
      {data.img ? (
        <ImageContainer url={urlImageHandler(data.img)}></ImageContainer>
      ) : (
        <></>
      )}
      <CardSectionContainer>
        <h4 className="font-bold text-2xl break-words text-left">
          {data.title.slice(0, Constants.MAX_TITLE_LENGTH)}
        </h4>
        <p className="break-words text-left">
          {data.text.slice(0, Constants.MAX_TEXT_LENGTH)}{' '}
          {data.text.length > Constants.MAX_TEXT_LENGTH ? '...' : ''}
        </p>
        {data.responsible ? (
          <Responsible>
            <p>{data.responsible}</p>
          </Responsible>
        ) : (
          <></>
        )}
        {data.tags.length ? (
          <TagsContainer>
            {data.tags.map((tag) => (
              <Chip text={tag.tag}>{undefined}</Chip>
            ))}
          </TagsContainer>
        ) : (
          <></>
        )}
      </CardSectionContainer>
    </CardContainer>
  );
};
