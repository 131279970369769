import { SidebarPortal } from '@plone/volto/components';
import React from 'react';
import CampiData from './Data';
import Campi from './View.jsx';

const EditComponent = (props) => {
  const { selected } = props;

  return (
    <div>
      <Campi {...props} isEditMode={true} />
      <SidebarPortal selected={selected}>
        <CampiData {...props} />
      </SidebarPortal>
    </div>
  );
};

export default EditComponent;
