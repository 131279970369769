//@ts-check
import React from 'react';
import { Swiper } from 'swiper/react';

/**
 * @type {React.FC<{children:React.ReactNode,allowTouchMove?:boolean}>}
 */
export const CardsCarouselContainer = ({ children, allowTouchMove = true }) => {
  return (
    <div className="relative w-full [.has-toolbar_&]:w-[calc(100%-80px)] [.has-sidebar-collapsed.has-toolbar_&]:w-full [.has-sidebar.has-toolbar_&]:w-full">
      <Swiper
        spaceBetween={16}
        slidesPerView={3}
        allowTouchMove={allowTouchMove}
        navigation={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
        }}
      >
        {children}
      </Swiper>
    </div>
  );
};
