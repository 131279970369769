//@ts-check
import React from 'react';
//@ts-ignore
import AddLinkForm from '@plone/volto/components/manage/AnchorPlugin/components/LinkButton/AddLinkForm';

import { assertCardDataTypes } from './assertCardDataTypes';
import { CardContainer } from '../CardContainer';
import { Responsible } from './Responsible';
import { AutoHeightTextArea } from '../../../general';
import { TagsContainer } from './TagsContainer';
import { CardSectionContainer } from '../CardSectionContainer';
import { urlImageHandler } from '../../../../helpers/urlImageHandle';
import { Constants } from './constants';
import { Chip, WarningContainer } from '../../../general';
import { ImageContainer } from './ImageContainer';
import { Subscribe } from './Subscribe';

/**
 * @import {CardDataType} from "./assertCardDataTypes";
 * @param {{data:unknown,setCard:(card:{}|undefined)=>any}} props
 */
export const CardEdit = (props) => {
  const data = assertCardDataTypes(props.data);

  const { setCard } = props;

  return (
    <CardContainer>
      {!data.img ? (
        <div className="flex items-center flex-row gap-4 flex-wrap">
          <span>link da imagem:</span>
          <AddLinkForm
            block="draft-js"
            placeholder={'Add image'}
            theme={{}}
            data={{ url: data.img }}
            onChangeValue={(url) => {
              data.img = url;
              setCard(data);
            }}
            onClear={() => {
              data.img = '';
              setCard(data);
            }}
            onOverrideContent={(c) => {}}
          ></AddLinkForm>
        </div>
      ) : (
        <div className="relative h-full w-full">
          <ImageContainer url={urlImageHandler(data.img)}></ImageContainer>
          <button
            className="absolute bg-red-500 p-2 top-0 right-0"
            onClick={() => {
              (data.img = ''), setCard(data);
            }}
          >
            remover imagem
          </button>
        </div>
      )}
      <CardSectionContainer>
        <AutoHeightTextArea
          onChange={(ev) => {
            data.title = ev.target.value || '';
            setCard(data);
          }}
          className={'text-2xl font-bold bg-gray-500/20 dark:text-dsGray-50'}
          value={data.title}
          placeholder="Título"
        ></AutoHeightTextArea>
        {data.title.length > Constants.MAX_TITLE_LENGTH ? (
          <WarningContainer className={undefined}>
            O limite é {Constants.MAX_TITLE_LENGTH} e seu título tem{' '}
            {data.title.length} caracteres, diminua o título
          </WarningContainer>
        ) : (
          <></>
        )}
        <AutoHeightTextArea
          onChange={(ev) => {
            data.text = ev.target.value || '';
            setCard(data);
          }}
          className={'bg-gray-500/20 dark:text-dsGray-50 '}
          value={data.text}
          placeholder="Texto"
        ></AutoHeightTextArea>
        {data.tags.length ? (
          <TagsContainer>
            {data.tags.map((tag) => (
              <Chip text={tag.tag}>{undefined}</Chip>
            ))}
          </TagsContainer>
        ) : (
          <></>
        )}
        <Subscribe>
          <AutoHeightTextArea
            onChange={(ev) => {
              data.subscribe = ev.target.value || '';
              setCard(data);
            }}
            className={'bg-gray-500/5 dark:text-dsGray-50'}
            value={data.subscribe}
            placeholder="Como participar"
          ></AutoHeightTextArea>
        </Subscribe>
        <Responsible>
          <AutoHeightTextArea
            onChange={(ev) => {
              data.responsible = ev.target.value || '';
              setCard(data);
            }}
            className={'bg-gray-500/5 dark:text-dsGray-50'}
            value={data.responsible}
            placeholder="Responsavél"
          ></AutoHeightTextArea>
        </Responsible>
      </CardSectionContainer>
    </CardContainer>
  );
};
